<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">เพิ่มระดับการเข้าถึง</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            @submit="onSubmitRole"
            :validation-schema="role"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-7 fv-row">
                <!--begin::Label-->
                <label
                  class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                >
                  <span class="required">ชื่อ</span>
                </label>
                <!--end::Label-->
                <Field
                  type="text"
                  name="name"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="ชื่อ"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <!--end::Input group-->
              <label class="fs-5 fw-bolder form-label mb-2">Setting</label>

              <div class="row">
                <div
                  v-for="permission in Settings"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <label class="fs-5 fw-bolder form-label mb-2">ร้องเรียน</label>

              <div class="row">
                <div
                  v-for="permission in Appeals"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <label class="fs-5 fw-bolder form-label mb-2"
                >หัวข้อร้องเรียน</label
              >

              <div class="row">
                <div
                  v-for="permission in Appeal_titles"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <label class="fs-5 fw-bolder form-label mb-2"
                >ส่งเสริมการท่องเที่ยว</label
              >

              <div class="row">
                <div
                  v-for="permission in Travels"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <label class="fs-5 fw-bolder form-label mb-2">ดุแลสุขภาพ</label>

              <div class="row">
                <div
                  v-for="permission in Smarthealths"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <label class="fs-5 fw-bolder form-label mb-2">สอบถามภาษี</label>

              <div class="row">
                <div
                  v-for="permission in Taxs"
                  :key="permission.id"
                  class="col-3 p-2"
                >
                  <label
                    class="form-check form-check-custom form-check-solid me-9"
                  >
                    <Field
                      type="checkbox"
                      name="permission"
                      class="form-check-input"
                      id="permission"
                      :value="permission.id"
                    />
                    <span class="form-check-label" for="kt_roles_select_all">{{
                      permission.title
                    }}</span>
                  </label>
                </div>
              </div>

              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="permission" />
                </div>
              </div>
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useRoles from "@/core/services/api/roles";
import { checkPage } from "@/core/helpers/checkpage";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "role-create",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { stoerRole, getPermissions, permissions } = useRoles();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const role = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      permission: Yup.array()
        .min(1, "กรุณากรอกเลือกสิทธิ์การเข้าถึงอย่างน้อย 1 การเข้าถึง")
        .required("กรุณากรอกเลือกสิทธิ์การเข้าถึง"),
    });

    const Settings = computed(() => {
      return permissions.value.filter((item) => item.mode === "setting");
    });
    const Appeals = computed(() => {
      return permissions.value.filter((item) => item.mode === "appeal");
    });
    const Appeal_titles = computed(() => {
      return permissions.value.filter((item) => item.mode === "appeal-title");
    });

    const Travels = computed(() => {
      return permissions.value.filter((item) => item.mode === "travel");
    });

    const Smarthealths = computed(() => {
      return permissions.value.filter((item) => item.mode === "smarthealth");
    });

    const Taxs = computed(() => {
      return permissions.value.filter((item) => item.mode === "tax");
    });

    const onSubmitRole = (values) => {
      Sconfirm("ยืนยัน การเพิ่มระดับการเข้าถึง", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            await stoerRole(values)
              .then(() => {
                SToast("success", "เพิ่ม ระดับการเข้าถึง สำเร็จ");
              })
              .catch((error) => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.name) {
                    SToast("error", `${errors.name[0]}`);
                  }
                }
              });
          }
        }
      );
    };

    onMounted(() => {
      getPermissions();
      checkPage("role-create");
      setCurrentPageBreadcrumbs("เพิ่มระดับการเข้าถึง", ["ระดับการเข้าถึง"]);
    });

    return {
      role,
      permissions,
      submitButton,
      onSubmitRole,
      Appeals,
      Settings,
      Appeal_titles,
      Travels,
      Smarthealths,
      Taxs,
    };
  },
});
</script>

<style></style>
